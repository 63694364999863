<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice/exercise' }">练习</el-breadcrumb-item>
        <el-breadcrumb-item>练习记录</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content flex">
        <div class="left">
          <span class="title theme-c"> 练习记录 </span>

          <div v-for="(item, index) in leftList" :key="item.id" @click="changeTab(index, item)"
            :class="[checkIndex === index ? 'theme-curr' : '', 'theme-h', 'f16']">
            <el-row :gutter="10" class="sec-d">
              <el-col :span="24">
                <span class="sec f16">{{ item.practiceName }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="right flex1">
          <div class="log-l">
            <span class="title"> 记录列表 </span>
            <div style="margin-top: 10px"></div>
            <template v-if="logList.length>0">
              <div :class="[index === currIndex ? 'top-solid' : '', 'list-i', 'pointer']"
                v-for="(item, index) in logList" :key="item.id" @click="changeItem(item, index)">
                <el-row :gutter="10">
                  <el-col :span="10" :class="[index === currIndex ? 'theme-c' : '', 'f16']">
                    {{ item.createDt | dataFormat }}
                  </el-col>
                  <el-col :span="14">
                    <div class="total f16">
                      <el-row :gutter="13">
                        <el-col :span="7">
                          答对：<span style="color: #289326">{{ item.trueNum }}题</span></el-col>
                        <el-col :span="7">
                          答错：<span style="color: #db3b3d">{{ item.falseNum }}题</span></el-col>
                        <el-col :span="9">正确率：<span style="color: #000">{{ item.rate * 100 }}%</span></el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </template>
             
            <NoneImg v-else />

          </div>

          <div class="pageInfo">
            <el-pagination background @current-change="handleCurrentChange" :current-page.sync="queryInfo.currPage"
              :page-size="queryInfo.pageSize" layout=" prev, pager, next,total, jumper" :total="count">
            </el-pagination>
          </div>

          <div class="flex justify-center">
            <div class="btn" @click="jump">开始练习</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      checkIndex: null,
      currIndex: null,
      // 分页参数
      queryInfo: {
        currPage: 1,
        pageSize: 8,
      },
      count: 0,
      courseCode: "",
      memberCode: "",
      leftList: [],
      logList: [],
      currItem: null,
    };
  },
  methods: {
    // 修改当前页
    handleCurrentChange (newCurr) {
      this.queryInfo.currPage = newCurr;
      this._getPracticeListLog();
    },

    changeTab (index, item) {
      this.checkIndex = index;
      this.currItem = item;
      this._getPracticeListLog(item.practiceCode);
    },

    jump () {
      if (!this.currItem) {
        // this.$message.error("请先选择练习");
         this.$message({
            message: "请先选择练习!",
            type: "error",
            offset:'400'
          });
        return;
      }
      this.$router.push({
        path: "/practice/exercise/DoExercise",
        query: { practiceCode: this.currItem.practiceCode },
      });
    },

    changeItem (item, index) {
      this.currIndex = index;
    },

    _getPracticeList () {
      // 获得左侧列表
      this.$api.exercise
        .getPracticeList({
          courseCode: this.courseCode,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.leftList = res.data.data;
            if (this.leftList.length > 0) {
              this.changeTab(0, this.leftList[0])
            }
          }
        });
    },

    _getPracticeListLog (practiceCode) {
      //记录列表
      this.$api.exercise
        .getPracticeListLog({
          practiceCode,
          memberCode: this.memberCode,
          currPage: this.queryInfo.currPage,
          pageSize: this.queryInfo.pageSize,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.logList = res.data.data.data;

            this.count = res.data.data.count;
          }
        });
    },
  },

  created () {
    this.courseCode = this.$route.query.courseCode;
    this.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
    this._getPracticeList();
  },
};
</script>

<style lang="less" scoped>
.content {
  text-align: left;
  margin: 30px 0;
  height: 710px;
  background: #f4f4f4;
  .left {
    background: #f9fbfc;
    width: 250px;
    height: 100%;
    padding: 12px 0;
    overflow-x: hidden;
    .title {
      font-weight: 700;
      font-size: 24px;
      padding: 0 20px;
    }
    .sec-d {
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      .sec {
        font-weight: 700;
      }
      .des {
        color: #7f7f7f;
      }
    }
    .gray-bg {
      background: #ccc;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    .log-l {
      height: 460px;
      padding: 12px 22px;
      .title {
        font-weight: 700;
        font-size: 25px;
        color: #7f7f7f;
      }
      .list-i {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #c1c4cc;
      }
    }
    .top-solid {
      border-top: 1px solid #c1c4cc;
    }
    .total {
      text-align: right;
      color: #7f7f7f;
    }
    .pageInfo {
      text-align: right;
    }
    .btn {
      margin-top: 30px;
      color: #fff;
      cursor: pointer;
      font-size: 18px;
      background: #db3b3d;
      width: 260px;
      height: 50px;
      line-height: 50px;
      border-radius: 41px;
      text-align: center;
    }
  }
}
</style>
